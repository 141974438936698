<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <div class="page-title">
              {{ $route.meta.title }}
            </div>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col md="6" cols="12">
                <form-control
                  inputType="dateRangePicker"
                  v-model="filterDateRange"
                  label="日期"
                  :disabled="isFetching"
                />
              </v-col>
              <v-col md="6" cols="12" class="d-flex align-center">
                <v-btn
                  color="error"
                  class="px-6 font-weight-bold"
                  depressed
                  small
                  :disabled="isFetching"
                  @click="filterDateRange = []"
                >
                  清除
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="secondary"
                  class="px-6 font-weight-bold"
                  depressed
                  :disabled="isFetching"
                  @click="downloadSalesReport"
                >
                  下載銷售報表
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="secondary"
                  class="px-6 font-weight-bold"
                  depressed
                  :disabled="isFetching"
                  @click="downloadUserReport"
                >
                  下載用戶報表
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import * as XLSX from 'xlsx'

export default {
  name: 'Report',
  components: {
    FormControl,
  },
  data() {
    return {
      filterDateRange: [],
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    async downloadSalesReport() {
      if (!this.isFetching) {
        this.setFetching(true)
        try {
          const payload = {
            verify_token: this.getAdminToken(),
          }

          if (this.filterDateRange.length === 1) {
            payload.start_date = this.filterDateRange[0]
          } else if (this.filterDateRange.length === 2) {
            payload.start_date = this.filterDateRange[0]
            payload.end_date = this.filterDateRange[1]
          }

          const result = await this.$XHR.api('cms_get_sales_report', payload)
          this.$func.log('-----Get Sales Report-----')
          this.$func.log(result)

          const header = [
            [
              //'建立日期',
              '訂單編號',
              '課程類型',
              '課程標題',
              '學生名稱',
              '電話',
              '電郵',
              '付款方式',
              '總金額',
              '已付金額',
            ],
          ]

          let workbook = XLSX.utils.book_new()
          let sheet1 = XLSX.utils.aoa_to_sheet(header.concat(result))
          XLSX.utils.book_append_sheet(workbook, sheet1, 'sales_report')

          const fileName = `銷售報表.xlsx`
          XLSX.writeFile(workbook, fileName)
        } catch (error) {
          this.$func.log('-----Get Sales Report fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else {
            msg = '沒有資料可供下載'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }
        } finally {
          this.setFetching(false)
        }
      }
    },
    async downloadUserReport() {
      if (!this.isFetching) {
        this.setFetching(true)
        try {
          const payload = {
            verify_token: this.getAdminToken(),
          }

          if (this.filterDateRange.length === 1) {
            payload.filter_create_start_date = this.filterDateRange[0]
          } else if (this.filterDateRange.length === 2) {
            payload.filter_create_start_date = this.filterDateRange[0]
            payload.filter_create_end_date = this.filterDateRange[1]
          }

          const result = await this.$XHR.api('cms_get_user', payload)
          this.$func.log('-----Get User Report-----')
          this.$func.log(result)

          const header = [['用戶名稱', '用戶類別', '電郵地址', '電話']]

          const data = []

          const userTypeList = this.$userTypeList

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            const tempData = []

            tempData.push(this.$func.getDateTimeStr(resultData.create_date))

            const userTypeIndex = userTypeList.findIndex(obj => {
              return obj.value === resultData.user_type
            })

            if (userTypeIndex !== -1) {
              tempData.push(userTypeList[userTypeIndex].text)
            } else {
              tempData.push('-')
            }

            tempData.push(resultData.email)

            if (this.$validate.DataValid(resultData.email)) {
              tempData.push(resultData.email)
            } else {
              tempData.push('-')
            }

            data.push(tempData)
          }

          let workbook = XLSX.utils.book_new()
          let sheet1 = XLSX.utils.aoa_to_sheet(header.concat(data))
          XLSX.utils.book_append_sheet(workbook, sheet1, 'user_report')

          const fileName = `用戶列表.xlsx`
          XLSX.writeFile(workbook, fileName)
        } catch (error) {
          this.$func.log('-----Get User Report fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else {
            msg = '沒有資料可供下載'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }
        } finally {
          this.setFetching(false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.$store.dispatch('toggleLoadingPage', false)
    }
  },
}
</script>
